import React from "react";
import NoSSR from "react-no-ssr";
import styled, { css } from "styled-components";
import Carousel from "nuka-carousel";
import { Previous, Next } from "grommet-icons";
import shuffle from "shuffle-array";
import Testimonial from "../Testimonial/Testimonial";
import { transformEdgesNodes } from "../utils";
import { breakpoint } from "../../theme";

const Arrow = styled.div`
	width: 48px;
	height: 48px;
	cursor: pointer;
	z-index: 5;

	${breakpoint("phone")`
		display: none;
	`}
`;

const PreviousArrow = ({ previousSlide }) =>
	<Arrow onClick={previousSlide} previous>
		<Previous />
	</Arrow>;

const NextArrow = ({ nextSlide }) =>
	<Arrow onClick={nextSlide} next>
		<Next />
	</Arrow>;

const noFocusOutline = css`
	&:focus{
		outline: none;
	}
`;

const Container = styled.div`
	position: relative;
	width: 100%;
	height: 100%;

	${noFocusOutline}

	${breakpoint("phone")`
		width: 350px;
	`}

	ul {
		${noFocusOutline}
	}

	li {
		${noFocusOutline}
	}

	.slider {
    ${noFocusOutline}
	}

	div[aria-live] { /*stupid aria message from nuka breaks the page on mobile view :( */
		display:none;
	}
`;

const TestimonialWrapper = styled.div`
	padding-bottom: 40px;
	${noFocusOutline}
`;

const TestimonialsCarousel = ({ testimonials }) => {
	return <NoSSR>
		<Container dir="ltr">
			<Carousel
				autoGenerateStyleTag={false}
				enableKeyboardControls={false}
				heightMode="max"
				slidesToShow={1}
				wrapAround
				disableAnimation
				renderCenterRightControls={NextArrow}
				renderCenterLeftControls={PreviousArrow}>
				{shuffle(transformEdgesNodes(testimonials)).map((t) =>
					<TestimonialWrapper key={t.name + t.role}>
						<Testimonial  {...t} />
					</TestimonialWrapper>)}
			</Carousel>
		</Container>
	</NoSSR>;
};

export default TestimonialsCarousel;
