//@flow
import React from "react";
import { graphql } from "gatsby";
import styled, { css } from "styled-components";
import { Text } from "grommet";
import Image from "../Image/Image";
import Quoted from "../Quoted/Quoted";
import { breakpoint } from "../../theme";

const Container = styled.div`
	display:flex;
	flex-direction: column;
	margin: 0 auto;
	justify-content: center;
	align-items: center;
`;

const TextWrapper = styled.div`
	display:flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	text-align: center;
`;

const TestimonialImage = styled(Image)`
	width: 140px;
	height: 100px;
`;

const TestimonialText = styled(Quoted)`
	font-size: 14px;
	line-height: 18px;
	max-width: 520px;
	font-style: italic;

	${breakpoint("phone")`
		max-width: 420px;
	`}

	${breakpoint("s-phone")`
		max-width: 312px;
	`}
`;

const Name = styled(Text)`
	font-weight: bold;
`;

const Role = styled(Text)`

`;

const ImageContainerStyles = css`
	display: flex;
	justify-content: center;
`;

const Testimonial = ({ name, role, content, image }) => {
	return <Container dir="rtl">
		{image && image.childImageSharp &&
			<TestimonialImage image={image} loading="lazy" containerStyles={ImageContainerStyles}/>}
		<TextWrapper>
			<TestimonialText content={content} />
			<Name>{name}</Name>
			<Role>{role}</Role>
		</TextWrapper>
	</Container>;
};

export const TestimonialFragment = graphql`
	fragment Testimonial on MarkdownRemarkEdge {
		node {
			id
      		html
			fields {
				slug
			}
			frontmatter {
				name
				role
			}
			...RemoteBannerImageNoTypeSmall
  		}
	}`;

export default Testimonial;
