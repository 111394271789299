import React from "react";
import styled, { css } from "styled-components";
import { HTMLContent } from "../Content";

const quoteSignSize = css`
			font-size: ${({ quoteSize }) => quoteSize}px;
			width: ${({ quoteSize }) => quoteSize}px;
			height: ${({ quoteSize }) => quoteSize}px;
`;

const QuotedText = styled(HTMLContent)`
	p:first-of-type {
		::before {
			content: '”';
			position: relative;
			${quoteSignSize}
			/* font-size: 32px;
			width: 32px;
			height: 32px; */
		}
	}

	p:last-of-type {
		::after {
			content: '“';
			position: relative;
			${quoteSignSize}
			/* font-size: 32px;
			width: 32px;
			height: 32px; */
			display: inline-block;
			transform: translateY(1.2rem);
			padding-right: 4px;
		}
	}
`;

const Quoted = ({ content, className, quoteSize }) => {
	return <QuotedText
		className={className}
		content={content}
		quoteSize={quoteSize || 32} />;
};

export default Quoted;
