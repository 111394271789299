import React, { useCallback } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Box, Heading, Text } from "grommet";
import { getHebrewDate } from "../utils";
import Image from "../Image/Image";
import NavLink from "../Link/NavLink";
import { color, breakpoint } from "../../theme";

export const LibraryHighlightFragment = graphql`
    fragment LibraryHighlight on MarkdownRemarkEdge {
        node {
            fields {
                slug
            }
            frontmatter {
                title
                description
                liveDate
            }
            ...RemoteBannerImageSmall
        }
    }`;

const ItemImage = styled(Image)`
	position: absolute;
	width: 100%;
	height: auto;
	max-height: 120px;
	min-height: 120px;
`;

const Container = styled(Box)`
	position: relative;
	min-width: 180px;
	width: 25%;
	max-width: 300px;
	margin-left: 10px;
	margin-bottom: 20px;
	box-shadow: 0 0 2px 3px ${color("brand-bg-dark", false)};

	${breakpoint("phone")`
		width: 80%;
	`}
`;

const Overlay = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	${color("brand-bg-overlay", "background-color")}
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const EventHeading = styled(Heading)`
	${color("white")}
	text-align: center;
`;

const getLiveDateInTheFuture = (item) => {
	let liveDate;

	try {
		const d = new Date(item.liveDate);
		if (d.getTime() > Date.now()) {
			liveDate = getHebrewDate(d);
		}
	} catch (e) {
	}

	return liveDate;
};

const LibraryHighlight = (props) => {
	const { item, onClick } = props;

	const liveDate = getLiveDateInTheFuture(item);

	const onItemClick = useCallback(() => {
		if (onClick) {
			onClick(item);
		}
	}, [item, onClick]);

	return <Container onClick={onItemClick}>
		<NavLink to={item.slug} onClick={onItemClick}>
			<ItemImage image={item.image} alt={item.imageAlt || item.title}/>

			<Overlay>
				<EventHeading level={3} size="medium" margin="none" responsive>
					{item.title}
				</EventHeading>
				{liveDate ? <Text color="white" margin="none" responsive>
					{liveDate}
				</Text> : null}
			</Overlay>
		</NavLink>
	</Container>;
};

export default LibraryHighlight;
