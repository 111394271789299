import React, { useRef, useState, useContext } from "react";
import styled from "styled-components";
import { ThemeContext } from "grommet";
import { Youtube } from "grommet-icons";
import YouTubePlayer from "youtube-player";
import { ClipLoader } from "react-spinners";
import { getColor } from "../../theme";
import useRunOnDocLoad from "../../hooks/useRunOnDocLoad";

const Container = styled.div`
	min-height: 300px;
	min-width: 300px;
	position: relative;
`;

const LoadingContainer = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
`;

const YoutubeIcon = styled(Youtube)`
	position: absolute;
	left: 10px;
	top: 8px;
`;

const VideoPlayer = ({ url, className }) => {
	const theme = useContext(ThemeContext);
	const [loading, setLoading] = useState(true);
	const containerRef = useRef();

	useRunOnDocLoad(() => {
		const elm = containerRef.current;

		if (elm) {
			const player = YouTubePlayer(elm, {
				videoId: url,
				autoplay: 0,
				width: elm.offsetWidth,
			});

			player.on("ready", () => {
				setLoading(false);
			});
		}
	});

	return <Container className={className} ref={containerRef}>
		{loading && <LoadingContainer>
			<ClipLoader size={60} color={getColor(theme, "brand")} />
			<YoutubeIcon size={"40px"} />
		</LoadingContainer>}
	</Container>;
};

export default VideoPlayer;
