import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Heading } from "grommet";
import Quoted from "../components/Quoted/Quoted";
import NewsletterForm from "../components/NewsletterForm/NewsletterForm";
import FeaturedContent from "../components/FeaturedContent/FeaturedContent";
import FeaturedEvents from "../components/FeaturedEvents/FeaturedEvents";
import FeaturedLibraryItems from "../components/FeaturedLibraryItems/FeaturedLibraryItems";
import PageBanner from "../components/PageBanner/PageBanner";
import PageSection from "../components/PageSection/PageSection";
import TestimonialsCarousel from "../components/TestimonialsCarousel/TestimonialsCarousel";
import VideoPlayer from "../components/VideoPlayer/VideoPlayer";
import AdSenseAd from "../components/AdNetworks/AdSenseAd";
import withLayoutAndData from "./generic/withLayoutAndData";
import { headingFamily, breakpoint, border } from "../theme";
import WhatsappButton from "../components/WhatsappButton";

// type Props = {
// 	page: Page & {
// 		mainQuote: {
// 			text: String,
// 			name: String,
// 		}
// 	},
// 	highlightedEvents: Object[],
// 	testimonials: Object[],
// 	posts: Object[],
// 	products: Object[],
// 	libraryItems: Object[],
// };

const MainQuoteContainer = styled.div`
  min-width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  ${border({ style: "dotted" })`bottom`}

  ${breakpoint("tablet")`
		margin: 10px 0 20px;
	`}

	h2 {
		margin: 5px 0 10px;
	}
`;

const TopContentContainer = styled(PageSection)`
  margin-top: 20px;
  padding-bottom: 30px;
`;

const TestimonialsSection = styled(PageSection)`
	flex-direction: column;

	h2 {
		margin-bottom: 30px;
	}
`;

const MainQuote = styled(Quoted)`
	text-align: center;
	font-size: 48px;
	font-weight: bold;
	line-height: 50px;
	${headingFamily}
`;

const WelcomeVideoPlayer = styled(VideoPlayer)`
	width: 500px;
	margin-bottom: 20px;

	${breakpoint("tablet")`
		width:90%;
	`}
`;

const ContactWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
`;

export const IndexPage = (props) => {
	const { page } = props;

	return (<div>
		<PageBanner title={page.title} heading={page.heading} subheading={page.subheading} image={page.image}
			yPosition={page.imageYPosition} />

		<TopContentContainer bottomBorder>
			<MainQuoteContainer>
				<MainQuote content={`<p>${page.mainQuote.text}</p>`} quoteSize={42} />
				<Heading level={2} size="medium">{page.mainQuote.name}</Heading>
			</MainQuoteContainer>

			<ContactWrapper>
				<WhatsappButton/>
				<NewsletterForm />
			</ContactWrapper>
			<WelcomeVideoPlayer url="GxOJUVeLlJU" />
		</TopContentContainer>

		{props.libraryItems.edges.length ? <PageSection bottomBorder>
			<FeaturedLibraryItems
				page={page.slug}
				items={props.libraryItems}
				title="ספריית אונליין"
			/>
		</PageSection> : null}

		{props.highlightedEvents.edges.length ? <PageSection bottomBorder>
			<FeaturedEvents
				page={page.slug}
				items={props.highlightedEvents}
				title="קורסים וסדנאות" />
		</PageSection> : null}

		<AdSenseAd slot="5309901384" format="auto" />

		<PageSection bottomBorder>
			<FeaturedContent items={props.posts} title="מאמרים" moreLink="/blog" />
		</PageSection>

		<TestimonialsSection bottomBorder>
			<Heading level={2}>לקוחות מספרים</Heading>
			<TestimonialsCarousel testimonials={props.testimonials} />
		</TestimonialsSection>

		<PageSection bottomBorder>
			<FeaturedContent items={props.products} title="מוצרים מומלצים" moreLink="/products" />
		</PageSection>

		<AdSenseAd slot="5309901384" format="auto" />
	</div>);
};

export const pageQuery = graphql`
  query IndexPageTemplate {
			markdownRemark(frontmatter: {templateKey: {eq: "index-page" } }) {
			frontmatter {
			title
        heading
        subheading
        mainQuote {
			text
          name
	  }
	}
	...RemoteBannerImage
  }
    posts: allMarkdownRemark(sort: {order: [DESC, DESC], fields: [frontmatter___featuredpost, frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "blog-post"}}}, limit: 4) {
			edges {
			...FeaturedContent
		}
	}
    products: allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "product-page"}}}, limit: 3) {
			edges {
			...FeaturedContent
		}
	}
	  library: allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "library-page"}, featuredpost: {eq: true}}}, limit: 5) {
	      edges {
	          ...LibraryHighlight
	      }
	  }
    highlightedEvents: allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "event-page"}, featuredpost: {eq: true}}}, limit: 4) {
			edges {
			...EventHighlight
		}
	}
	testimonials: allMarkdownRemark(filter: {frontmatter: {type: {eq: "testimonial"}}}, limit: 100) {
			edges {
			...Testimonial
		}
	}
}`;

export default withLayoutAndData(
	({ data }) => ({
		page: {
			...data.markdownRemark.frontmatter,
			slug: "/"
		},
		posts: data.posts,
		products: data.products,
		libraryItems: data.library,
		testimonials: data.testimonials,
		highlightedEvents: data.highlightedEvents,
	}), () => ({
		ogTags: { title: "מונטסורי בייבי - מרכז לייעוץ והדרכה בשיטת מונטסורי" },
		titleTemplateOverride: "%s"
	})
)(IndexPage);
