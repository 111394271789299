import React from "react";
import LibraryHighlight from "../LibraryHighlight/LibraryHighlight";
import FeaturedContent from "../FeaturedContent/FeaturedContent";

// type Props = {
// 	title?: String,
// 	items: Object[],
// 	page: String,
// };

const FeaturedLibraryItems = (props) => {
	return (<FeaturedContent
		{...props}
		moreLink="/library"
		containerJustify="center"
		ItemComponent={LibraryHighlight}
	/>);
};

export default FeaturedLibraryItems;


